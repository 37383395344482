<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="statement.is_payed").col-12
    +select('body.status_document', 'statusChoose("StatementMedicalCertificate")', 'status', 'labelName')
  div(v-if="STATUSES_STATEMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED === body.status_document").col-12
    Reject
  div(v-else-if="!statement.is_payed").col-12
    +select('body.is_payed', 'paymentStatusesList', 'payment', 'labelName')(item-value="status")
  div(v-if="STATUSES_STATEMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED !== body.status_document").col-12.mt-3
    v-btn(@click="updateApplicationStatus" :loading="isLoading" color="success") {{ $t('btn.save') }}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { SUCCESS_CODE, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'

export default {
  components: {
    Reject: () => import('./Reject.vue')
  },
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      body: {
        is_payed: null,
        status_document: this.statement.status_document
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['paymentStatusByStatus', 'statusChoose']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      paymentStatusesList: state => state.directory.paymentStatus
    })
  },
  mounted () {
    this.body.is_payed = this.statement.is_payed
  },
  methods: {
    ...mapActions(['updateMedicalStatementById']),

    async updateApplicationStatus () {
      this.isLoading = true

      if (this.statement.is_payed) delete this.body.is_payed
      else delete this.body.status_document

      const response = await this.updateMedicalStatementById({ ...this.$route.params, body: { ...this.body } })
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('medicalStatementEdited')
      this.isLoading = false
    }
  }
}
</script>
